// External imports
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

// Global imports
import { User } from 'assets/models/User';
import { EncryptedLocalStorageService } from '../storage';

import { NavigationService } from '../navigation';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { Division } from 'assets/constants/enums';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private logInStateSubject = new BehaviorSubject<boolean>(false);
	userLoggedInObservable = this.logInStateSubject.asObservable();

	private refreshInterval: any;

	constructor(
		private storage: EncryptedLocalStorageService,
		private readonly navigationService: NavigationService,
		private readonly http: HttpClient,
		private readonly toastr: ToastrService
	) {
		// Subject state initialization
		this.logInStateSubject.next(!this.isTokenExpired());
		// Subject emitter
		storage.observe('expires').subscribe(() => {
			this.logInStateSubject.next(!this.isTokenExpired());
		});
	}

	storeAuth(token: string, expires: number, refreshToken?: string): void {
		try {
			this.storage.secureStore('token', token);
			this.storage.secureStore('refreshToken', refreshToken);
			this.storage.store('expires', expires);

			const params = new URLSearchParams(window.location.search);
			const redirectTo = params.get('redirectTo');

			if (redirectTo) {
				this.navigationService.handleSecureRedirect(redirectTo, token);
			}
		} catch (error) {
			console.error('Error storing auth data:', error);
			throw new Error('Failed to store auth data');
		}
	}

	configureAuthListener = () => {
		Hub.listen('auth', async (data) => {
			switch (data.payload.event) {
				case 'signedIn': {
					const attributes = await fetchUserAttributes();
					const user = {
						email: attributes.email,
						firstname: attributes['given_name'],
						lastname: attributes['family_name'],
						_id: attributes['custom:mongo_id'],
						audaraId: attributes['custom:audara_id'],
						branch: attributes['custom:branch'],
						roles: attributes['custom:role'] ? attributes['custom:role'].split(',') : [],
						defaultDivision: attributes['custom:default_division'] || Division.All,
						defaultAccount: attributes['custom:default_account'],
						status: attributes['custom:status'],
					};
					this.storeUserAttributes(user, this.storage);

					const session = await fetchAuthSession();
					const expirationTimestamp = session.tokens.accessToken.payload.exp * 1000;
					this.storeAuth(session.tokens.accessToken.toString(), expirationTimestamp);
					this.storeUser(user as any);
					this.startTokenMonitor();
					this.navigationService.navigatePost('/dashboard');

					break;
				}
				case 'tokenRefresh_failure': {
					this.stopTokenMonitor();
					this.storage.clear();
					this.toastr.error('Your session has expired. Please log in again.');
					this.navigationService.navigatePost('/login');
					break;
				}
			}
		});
	};

	storeUserAttributes(user: any, storage: EncryptedLocalStorageService): void {
		storage.store('_id', user._id);
		storage.secureStore('audaraId', user.audaraId);
		storage.store('roles', JSON.stringify(user.roles));
		storage.store('email', user.email);
		storage.store('employeeName', `${user.firstname} ${user.lastname}`);
		storage.store('employeeBranch', user.branch ? user.branch : 'Puerto Rico');
		storage.store('employeeDivision', user.defaultDivision || Division.All);
		storage.store('employeeDefaultAccount', user.defaultAccount);

		let isCoordinator = false;
		let isAdmin = false;

		Object.values(user.roles).forEach((role: string) => {
			if (role === 'coordinator') {
				isCoordinator = true;
			}
			if (role === 'role_admin') {
				isAdmin = true;
			}
		});

		const noCoordinatorOrIsAdmin = !isCoordinator || isAdmin;
		const isCoordinatorOrIsAdmin = isCoordinator || isAdmin;

		storage.secureStore('agentLogsAccess', noCoordinatorOrIsAdmin);
		storage.secureStore('agentUsersAccess', noCoordinatorOrIsAdmin);
		storage.secureStore('agentSalesAccess', noCoordinatorOrIsAdmin);
		storage.secureStore('agentSubscriptionsAccess', noCoordinatorOrIsAdmin);

		storage.secureStore('agentDispatchAccess', isCoordinatorOrIsAdmin);
		storage.secureStore('agentDashboardAccess', isCoordinatorOrIsAdmin);

		storage.secureStore('showDriverMarkers', false);
	}

	checkAuthAndRedirect(): void {
		const params = new URLSearchParams(window.location.search);
		const redirectTo = params.get('redirectTo');

		if (redirectTo) {
			const token = this.storage.secureRetrieve('token');
			const expires = this.storage.retrieve('expires');

			if (token && expires && new Date().getTime() < expires) {
				this.navigationService.handleSecureRedirect(redirectTo, token);
			} else {
				this.navigationService.navigatePost('/login', { redirectTo });
			}
		}
	}

	storeUser(user: User): void {
		this.storage.store('user', user);
	}

	getAuthToken(): string {
		return this.storage.secureRetrieve('token');
	}

	getRefreshToken(): string {
		return this.storage.secureRetrieve('refreshToken');
	}

	getUserId(): string {
		return this.storage.retrieve('_id');
	}

	isTokenExpired(): boolean {
		return Date.now() > this.storage.retrieve('expires');
	}

	isTokenAboutToExpire(): boolean {
		const expires = this.storage.retrieve('expires');
		const now = Date.now();

		const timeUntilExpiration = expires - now;
		const bufferTime = 3 * 60 * 1000;

		return timeUntilExpiration <= bufferTime;
	}

	async refreshCognitoToken(): Promise<void> {
		try {
			const session = await fetchAuthSession({ forceRefresh: true });

			if (session.tokens) {
				const expirationTime = session.tokens.accessToken.payload.exp * 1000;
				this.storeAuth(session.tokens.accessToken.toString(), expirationTime);
			}
		} catch (error) {
			console.error('Error refreshing cognito token:', error);
			this.stopTokenMonitor();
			this.storage.clear();
			this.toastr.error('Your session has expired. Please log in again.');
			this.navigationService.navigatePost('/login');
		}
	}

	startTokenMonitor(): void {
		console.log('startTokenMonitor');
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval);
		}
		this.refreshInterval = setInterval(() => {
			if (this.isTokenAboutToExpire()) {
				this.refreshCognitoToken();
			}
		}, 60 * 1000);
	}

	stopTokenMonitor(): void {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval);
		}
	}

	async updateUserAttributes(
		email: string,
		userType: 'user' | 'corporate' | 'provider',
		userAttributes: { Name: string; Value: string }[]
	): Promise<boolean> {
		const url = `${environment.COGNITO_SERVICE_URL}/update-cognito-user`;
		const headers = new HttpHeaders().set('x-api-key', environment.COGNITO_SERVICE_API_KEY);
		const payload = {
			email,
			userType,
			userAttributes,
		};

		try {
			await lastValueFrom(this.http.post(url, payload, { headers }));
			return true;
		} catch (error) {
			this.toastr.error('Error updating cognito user attributes');
			console.error('Error updating user attributes:', error);
			return false;
		}
	}
}
