// External imports
import { Injectable } from '@angular/core';
import { keyBy } from 'lodash-es';
import { LocalStorageService } from 'ngx-webstorage';

// Global imports
import { Branch } from 'assets/models/Branch';
import { Division } from 'assets/constants/enums';
import { AccountService } from 'modules/core/services/api/account.service';
import { ProviderService } from 'modules/core/services/api/providers.service';
import { ServiceService } from 'modules/core/services/api/service.service';

// Local imports
import { BehaviorSubject } from 'rxjs';
import { IProvider } from '../../../../../assets/models/Provider';
import { HttpService } from '../../api/http.service';
import { INITIAL_STATE } from './constants';
import { GetServiceTypeResponse, GlobalState } from './types';
import { ServiceType } from 'assets/models/ServiceType';
import { UserService } from '../../api/user.service';

@Injectable({
	providedIn: 'root',
})
export class GlobalStateService {
	private stateInitialized = false;
	private initializingSubject = new BehaviorSubject<boolean>(false);

	private state: GlobalState = {
		branch: new BehaviorSubject(INITIAL_STATE.branch),
		division: new BehaviorSubject(INITIAL_STATE.division),
		serviceTypes: new BehaviorSubject(INITIAL_STATE.serviceTypes),
		serviceTypesDict: new BehaviorSubject(INITIAL_STATE.serviceTypesDict),
		accounts: new BehaviorSubject(INITIAL_STATE.accounts),
		accountsBySfId: new BehaviorSubject(INITIAL_STATE.accountsBySfId),
		providers: new BehaviorSubject(INITIAL_STATE.providers),
		drivers: new BehaviorSubject(INITIAL_STATE.drivers),
		dispatchers: new BehaviorSubject(INITIAL_STATE.dispatchers),
		auditReasons: new BehaviorSubject(INITIAL_STATE.auditReasons),
		accountsPolicySymbols: new BehaviorSubject(INITIAL_STATE.accountsPolicySymbols),
	};
	private stateObservables = {
		branch: this.state.branch.asObservable(),
		division: this.state.division.asObservable(),
		serviceTypes: this.state.serviceTypes.asObservable(),
		serviceTypesDict: this.state.serviceTypesDict.asObservable(),
		accounts: this.state.accounts.asObservable(),
		accountsBySfId: this.state.accountsBySfId.asObservable(),
		providers: this.state.providers.asObservable(),
		drivers: this.state.drivers.asObservable(),
		auditReasons: this.state.auditReasons.asObservable(),
		accountsPolicySymbols: this.state.accountsPolicySymbols.asObservable(),
	};

	constructor(
		private serviceService: ServiceService,
		private accountService: AccountService,
		private providerService: ProviderService,
		private usersService: UserService,
		private storage: LocalStorageService,
		private httpService: HttpService
	) {
		const defaultBranch = this.storage.retrieve('employeeBranch');
		if (defaultBranch) {
			this.state.branch.next(defaultBranch);
		}

		const defaultDivision = this.storage.retrieve('employeeDivision');
		if (defaultDivision) {
			this.state.division.next(defaultDivision);
		}
	}

	async initState() {
		if (!this.stateInitialized) {
			this.initializingSubject.next(true);
			const defaultBranch = this.storage.retrieve('employeeBranch');
			if (defaultBranch) {
				this.state.branch.next(defaultBranch);
			}

			const defaultDivision = this.storage.retrieve('employeeDivision');
			if (defaultDivision) {
				this.state.division.next(defaultDivision);
			}

			const [serviceTypeData, accountData, providers, auditReasons] = await Promise.all([
				this.initServiceTypes(),
				this.initAccounts(),
				this.initProviders(),
				this.initAuditReasons(),
			]);
			const { serviceTypes, serviceTypesDict } = serviceTypeData;
			const { accounts } = accountData;

			this.state.serviceTypes.next(serviceTypes);
			this.state.serviceTypesDict.next(serviceTypesDict);
			this.state.accounts.next(accounts);
			this.state.providers.next(providers);
			this.state.auditReasons.next(auditReasons);

			const accountsBySfId = keyBy(accounts, 'salesforce');
			this.state.accountsBySfId.next(accountsBySfId);

			this.stateInitialized = true;
			this.initializingSubject.next(false);
		}
	}

	async initServiceTypes() {
		try {
			const response = await this.serviceService.getTypes();
			if (response.status) {
				const alphabeticallySortedServiceTypes = response.data?.sort((a, b) => (a.label > b.label ? 1 : -1)) || [];

				const serviceTypesDict = new Map();
				alphabeticallySortedServiceTypes.forEach((serviceType) => {
					serviceTypesDict.set(serviceType.name, serviceType);
				});

				return {
					serviceTypes: alphabeticallySortedServiceTypes,
					serviceTypesDict,
				};
			} else {
				throw new Error('Unable to init serviceTypes');
			}
		} catch (error) {
			console.error(error.message);
			return {
				serviceTypes: [],
				serviceTypesDict: new Map(),
			};
		}
	}

	async initAccounts() {
		try {
			const response = await this.accountService.getAll();
			if (response.status) {
				const alphabeticallySortedAccounts = response.data?.sort((a, b) => (a.name > b.name ? 1 : -1)) || [];

				return {
					accounts: alphabeticallySortedAccounts,
				};
			} else {
				throw new Error('Unable to init accounts');
			}
		} catch (error) {
			console.error(error.message);
			return {
				accounts: [],
			};
		}
	}

	async updateAccount() {
		try {
			const { accounts } = await this.initAccounts();
			this.state.accounts.next(accounts);
		} catch (error) {
			console.error(error.message);
		}
	}

	async initAuditReasons() {
		try {
			const response = await this.httpService.getAuditReasons();
			if (response.status) {
				return response.data;
			} else {
				throw new Error('Unable to init audit reasons');
			}
		} catch (error) {
			console.error(error.message);
			return {
				auditReasons: [],
			};
		}
	}

	async initProviders() {
		try {
			const simpleProviderProjection = {
				name: 1,
				branch: 1,
				email: 1,
				status: 1,
				_id: 1,
				vendorId: 1,
				providerId: 1,
				phone: 1,
				phone2: 1,
				providerName: 1,
				rates: 1,
				zones: 1,
				accounts: 1,
				webHooks: 1,
				ivaPercent: 1,
				socialReason: 1,
				taxId: 1,
				retentionPercent: 1,
				techRetentionPercent: 1,
				ratesVersion: 1,
			};
			const data = await this.providerService.getProviders(simpleProviderProjection).toPromise();
			if (data) {
				const alphabeticallySortedProviders = data?.sort((a, b) => (a.name > b.name ? 1 : -1)) || [];
				const providers = {
					[Branch.ALL]: alphabeticallySortedProviders,
					[Branch.PR]: [],
					[Branch.PA]: [],
					[Branch.CR]: [],
					[Branch.CO]: [],
					[Branch.MX]: [],
				};
				data?.forEach((provider) => {
					switch (provider.branch) {
						case Branch.CO:
							providers[Branch.CO].push(provider);
							break;
						case Branch.CR:
							providers[Branch.CR].push(provider);
							break;
						case Branch.MX:
							providers[Branch.MX].push(provider);
							break;
						case Branch.PA:
							providers[Branch.PA].push(provider);
							break;
						case Branch.PR:
							providers[Branch.PR].push(provider);
							break;
					}
				});
				return providers;
			} else {
				throw new Error('Unable to init providers');
			}
		} catch (error) {
			console.error(error.message);
			return {
				[Branch.ALL]: [],
				[Branch.PR]: [],
				[Branch.PA]: [],
				[Branch.CR]: [],
				[Branch.CO]: [],
				[Branch.MX]: [],
			};
		}
	}

	async initDrivers() {
		try {
			const drivers = await this.providerService.listDrivers();
			this.state.drivers.next(drivers);
		} catch (error) {
			this.state.drivers.next([]);
		}
	}

	async initDispatchers() {
		try {
			const dispatchers = await this.usersService.getUsersByAccess('dispatch_open');
			this.state.dispatchers.next(
				dispatchers.data.map((args) => ({ ...args, name: `${args.firstname} ${args.lastname}` }))
			);
		} catch (error) {
			this.state.dispatchers.next([]);
		}
	}

	clearState() {
		this.state.branch.next(INITIAL_STATE.branch);
		this.state.division.next(INITIAL_STATE.division);
		this.state.serviceTypes.next(INITIAL_STATE.serviceTypes);
		this.state.serviceTypesDict.next(INITIAL_STATE.serviceTypesDict);
		this.state.accounts.next(INITIAL_STATE.accounts);
		this.state.accountsBySfId.next(INITIAL_STATE.accountsBySfId);
		this.state.auditReasons.next(INITIAL_STATE.auditReasons);
		this.state.accountsPolicySymbols.next(INITIAL_STATE.accountsPolicySymbols);
		this.stateInitialized = false;
	}

	getObservables() {
		return this.stateObservables;
	}

	getServiceTypes() {
		return this.state.serviceTypes.getValue();
	}

	getAuditReasons() {
		return this.state.auditReasons.getValue();
	}

	getServiceTypeByName(serviceTypeName: string) {
		return this.state.serviceTypesDict.getValue().get(serviceTypeName);
	}

	getServiceTypeFields(situation: string) {
		return this.getServiceTypeByName(situation)?.fields;
	}

	serviceTypeIsSchedullable(situation: string) {
		return this.getServiceTypeByName(situation)?.schedulable ?? false;
	}

	/**
	 * Not to be confused with TYPE field.
	 * Should return one of the following values: 'vial', 'tow', 'inspections' or 'other'.
	 * Inspection type is also Vial type.
	 * @param situation
	 */
	getServiceType(situation: string): GetServiceTypeResponse {
		if (this.getServiceTypeByName(situation)?.isVial && this.getServiceTypeByName(situation)?.isInspection)
			return 'inspections';
		if (this.getServiceTypeByName(situation)?.isVial && !this.getServiceTypeByName(situation)?.isInspection)
			return 'vial';
		if (this.getServiceTypeByName(situation)?.isTow) return 'tow';
		return 'other';
	}

	getAccounts() {
		return this.state.accounts.getValue();
	}

	getAccountsBySfId() {
		return this.state.accountsBySfId.getValue();
	}

	getProvidersByBranch(branch: Branch) {
		return this.state.providers.getValue()[branch];
	}

	getProvidersByBranches(branches: Branch[]): IProvider[] {
		const providers = this.state.providers.getValue();

		if (branches.includes(Branch.ALL)) {
			// If 'Global' is in the branches array, return all providers
			return Object.values(providers).flat();
		}

		// Combine providers from selected branches
		return branches.flatMap((branch) => providers[branch]);
	}

	async getOrInitDrivers() {
		if (this.state.drivers.getValue().length) return this.state.drivers.getValue();
		else {
			await this.initDrivers();
			return this.state.drivers.getValue();
		}
	}

	async getOrInitDispatchers() {
		if (this.state.dispatchers.getValue().length) return this.state.dispatchers.getValue();
		else {
			await this.initDispatchers();
			return this.state.dispatchers.getValue();
		}
	}

	getBranch() {
		return this.state.branch.getValue();
	}

	setBranch(branch: Branch) {
		this.state.branch.next(branch);
	}

	getDivision() {
		return this.state.division.getValue();
	}

	setDivision(division: Division) {
		this.state.division.next(division);
	}

	async refreshProviders() {
		const providers = await this.initProviders();
		this.state.providers.next(providers);
	}

	getInitializingObservable() {
		return this.initializingSubject.asObservable();
	}

	setInitializing(value: boolean) {
		this.initializingSubject.next(value);
	}
	/**
	 * It takes a category as a parameter, and returns a filtered array of service types that match the
	 * category
	 * @param {string} category - string - The category of the service type you want to get.
	 * @returns An array of ServiceType objects.
	 */
	public getServiceTypesByCategory(category: string) {
		return this.getServiceTypes().filter((serviceType: ServiceType) => {
			return serviceType.category === category;
		});
	}

	/**
	 * It returns a filtered list of service types that have a branch that matches the searched branch
	 * @param {string} searchedBranch - string - The branch we're searching for
	 * @returns An array of ServiceType objects.
	 */
	public getServiceTypesByBranch(searchedBranch: string) {
		return this.getServiceTypes().filter((serviceType: ServiceType) => {
			return serviceType.branches.find((branch) => branch === searchedBranch);
		});
	}

	setAccountsPolicySymbols(account, policies = []) {
		this.state.accountsPolicySymbols.next({ ...this.state.accountsPolicySymbols.getValue(), [account]: policies });
	}

	getAccountsPolicySymbols() {
		return this.state.accountsPolicySymbols.getValue();
	}

	isInitialiazed() {
		return this.stateInitialized;
	}
}
