import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth';
import { EncryptedLocalStorageService } from '../services/storage';

@Injectable()
export class AuthGuard {
	constructor(
		private router: Router,
		private auth: AuthenticationService,
		private storage: EncryptedLocalStorageService
	) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		if (this.auth.isTokenAboutToExpire()) {
			this.auth.refreshCognitoToken();
		}

		if (!this.auth.isTokenExpired()) {
			return true;
		}

		this.redirectToLogin(route);
		return false;
	}

	private redirectToLogin(route: ActivatedRouteSnapshot): void {
		const qParams = [];
		if (Object.keys(route.queryParams).length) {
			Object.keys(route.queryParams).forEach((key) => qParams.push(`${key}=${route.queryParams[key]}`));
		}
		this.router.navigate([`/login`], { queryParams: { redirectTo: `${route.url.join('/')}?${qParams.join('&')}` } });
	}
}

@Injectable()
export class UnAuthGuard {
	constructor(private auth: AuthenticationService) {}

	canActivate(): boolean {
		return this.auth.isTokenExpired();
	}
}
